/* global site */

// Import site modules
import ('./site/anims.js');
import ('./site/products.js');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
    } else {
      // IE 11 || Old Browser - Ignore zone
      console.table(options);

      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
          // console.log('ServiceWorker unregistered');
        }
      });

      // PreRender for fast website
      function prerenderlink(e) {
        var head = document.getElementsByTagName("head")[0];
        var refs = head.childNodes;
        var ref = refs[ refs.length - 1];

        var elements = head.getElementsByTagName("link");
        Array.prototype.forEach.call(elements, function(el, i) {
          if (("rel" in el) && (el.rel === "prerender"))
            el.parentNode.removeChild(el);
        });

        var prerenderTag = document.createElement("link");
        prerenderTag.rel = "prerender";
        prerenderTag.href = e.currentTarget.href;
        ref.parentNode.insertBefore(prerenderTag,  ref);
      }

      const $prerenderLinks = document.querySelectorAll('.main-header a');
      [].slice.call($prerenderLinks).forEach(function(elem) {
        elem.addEventListener('mouseenter', prerenderlink);
      });
    }

    //Menu mobile
    var hamburger = document.querySelector("#hamburger");
    var mobilemenu = document.querySelector("#mobilemenu");
    var overlay = document.querySelector("#overlay");

    hamburger.addEventListener('click', function() {
      hamburger.classList.toggle("open");
      mobilemenu.classList.toggle("open");
    }, false);

    overlay.addEventListener('click', function() {
      hamburger.classList.toggle("open");
      mobilemenu.classList.toggle("open");
    }, false);

    var linkWithSubNav = document.querySelectorAll(".with-subnav");
    var hoverTimeout;

    [].slice.call(linkWithSubNav).forEach(function(elem) {
      elem.addEventListener('mouseenter', function(e) {
        e.preventDefault();

        clearTimeout(hoverTimeout);
        hoverTimeout = null;
        
        var subnav = elem.querySelector(".subnav");
        subnav.classList.add("is-active");

        subnav.addEventListener('mouseleave', function(e) {
          hoverTimeout = setTimeout(function() {
            subnav.classList.remove("is-active");
            hoverTimeout = null;
          }, 500);
        } , false);
      });
    });

    var stateItem = document.querySelectorAll(".state");

    [].slice.call(stateItem).forEach(function(elem) {
      elem.addEventListener('click', function(e) {
        e.preventDefault();

        if (elem.classList.contains("off")) {
          elem.classList.remove("off");
          elem.classList.add("on");
        } else {
          elem.classList.remove("on");
          elem.classList.add("off");
        }
      });
    });

    if (document.querySelector('.contact-wrap')) {
      // When the window has finished loading create our google map below
      google.maps.event.addDomListener(window, 'load', initContact);

      function initContact() {
          // Basic options for a simple Google Map
          var mapTheme = [{featureType:"administrative",elementType:"labels.text.fill",stylers:[{color:"#6195a0"}]},{featureType:"administrative.province",elementType:"geometry.stroke",stylers:[{visibility:"off"}]},{featureType:"landscape",elementType:"geometry",stylers:[{lightness:"0"},{saturation:"0"},{color:"#f5f5f2"},{gamma:"1"}]},{featureType:"landscape.man_made",elementType:"all",stylers:[{lightness:"-3"},{gamma:"1.00"}]},{featureType:"landscape.natural.terrain",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"poi.park",elementType:"geometry.fill",stylers:[{color:"#bae5ce"},{visibility:"on"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45},{visibility:"simplified"}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.highway",elementType:"geometry.fill",stylers:[{color:"#fac9a9"},{visibility:"simplified"}]},{featureType:"road.highway",elementType:"labels.text",stylers:[{color:"#4e4e4e"}]},{featureType:"road.arterial",elementType:"labels.text.fill",stylers:[{color:"#787878"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"transit.station.airport",elementType:"labels.icon",stylers:[{hue:"#0a00ff"},{saturation:"-77"},{gamma:"0.57"},{lightness:"0"}]},{featureType:"transit.station.rail",elementType:"labels.text.fill",stylers:[{color:"#43321e"}]},{featureType:"transit.station.rail",elementType:"labels.icon",stylers:[{hue:"#ff6c00"},{lightness:"4"},{gamma:"0.75"},{saturation:"-68"}]},{featureType:"water",elementType:"all",stylers:[{color:"#eaf6f8"},{visibility:"on"}]},{featureType:"water",elementType:"geometry.fill",stylers:[{color:"#c7eced"}]},{featureType:"water",elementType:"labels.text.fill",stylers:[{lightness:"-49"},{saturation:"-53"},{gamma:"0.79"}]}];

          // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
          var mapOptions = {
              // How zoomed in you want the map to start at (always required)
              zoom: 9,

              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,

              // The latitude and longitude to center the map (always required)
              center: new google.maps.LatLng(45.062550, -72.849810),

              // How you would like to style the map.
              // This is where you would paste any style found on Snazzy Maps.
              styles: mapTheme
          };

          // Get the HTML DOM element that will contain your map
          // We are using a div with id="map" seen below in the <body>
          var mapElement = document.getElementById('map');

          // Create the Google Map using our element and options defined above
          var map = new google.maps.Map(mapElement, mapOptions);

          // Let's also add a marker while we're at it
          var marker = new google.maps.Marker({
              position: new google.maps.LatLng(45.062550, -72.849810),
              map: map,
              icon: '/img/map-pin.svg',
              title: 'Héritage77'
          });
      }
    }

    if (document.querySelector('.drive-to-store-wrap')) {
      // When the window has finished loading create our google map below
      google.maps.event.addDomListener(window, 'load', initAcheter);

      function getJSON(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'json';
        xhr.onload = function() {
          var status = xhr.status;
          if (status === 200) {
            callback(null, xhr.response);
          } else {
            callback(status, xhr.response);
          }
        };
        xhr.send();
      }

      function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1);
        var a =
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c; // Distance in km
        return d;
      }

      function deg2rad(deg) {
        return deg * (Math.PI/180)
      }

      function initAcheter() {
          var mapTheme = [{featureType:"administrative",elementType:"labels.text.fill",stylers:[{color:"#6195a0"}]},{featureType:"administrative.province",elementType:"geometry.stroke",stylers:[{visibility:"off"}]},{featureType:"landscape",elementType:"geometry",stylers:[{lightness:"0"},{saturation:"0"},{color:"#f5f5f2"},{gamma:"1"}]},{featureType:"landscape.man_made",elementType:"all",stylers:[{lightness:"-3"},{gamma:"1.00"}]},{featureType:"landscape.natural.terrain",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"poi.park",elementType:"geometry.fill",stylers:[{color:"#bae5ce"},{visibility:"on"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45},{visibility:"simplified"}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.highway",elementType:"geometry.fill",stylers:[{color:"#fac9a9"},{visibility:"simplified"}]},{featureType:"road.highway",elementType:"labels.text",stylers:[{color:"#4e4e4e"}]},{featureType:"road.arterial",elementType:"labels.text.fill",stylers:[{color:"#787878"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"transit.station.airport",elementType:"labels.icon",stylers:[{hue:"#0a00ff"},{saturation:"-77"},{gamma:"0.57"},{lightness:"0"}]},{featureType:"transit.station.rail",elementType:"labels.text.fill",stylers:[{color:"#43321e"}]},{featureType:"transit.station.rail",elementType:"labels.icon",stylers:[{hue:"#ff6c00"},{lightness:"4"},{gamma:"0.75"},{saturation:"-68"}]},{featureType:"water",elementType:"all",stylers:[{color:"#eaf6f8"},{visibility:"on"}]},{featureType:"water",elementType:"geometry.fill",stylers:[{color:"#c7eced"}]},{featureType:"water",elementType:"labels.text.fill",stylers:[{lightness:"-49"},{saturation:"-53"},{gamma:"0.79"}]}];

          // Basic options for a simple Google Map
          // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
          var mapOptions = {
              // How zoomed in you want the map to start at (always required)
              zoom: 15,

              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,

              // The latitude and longitude to center the map (always required)
              center: new google.maps.LatLng(45.613560, -73.666930),

              // How you would like to style the map.
              // This is where you would paste any style found on Snazzy Maps.
              styles: mapTheme
          };

          // Get the HTML DOM element that will contain your map
          // We are using a div with id="map" seen below in the <body>
          var mapElement = document.getElementById('map');

          // Create the Google Map using our element and options defined above
          var map = new google.maps.Map(mapElement, mapOptions);

          // Let's also add a marker while we're at it
          var markers = [];

          // Clear out the old markers.
          markers.forEach(function(marker) {
            marker.setMap(null);
          });
          markers = [];

          // For each place, get the icon, name and location.
          var bounds = new google.maps.LatLngBounds();
          var boundsLength = 0;
          var places = [].slice.call(document.querySelectorAll('.place'));
          places.forEach(function(place) {

            var placeLocation = new google.maps.LatLng(place.getAttribute('data-place-lat'), place.getAttribute('data-place-lng'));

            var icon = {
              url: '/img/map-pin.svg',
              size: new google.maps.Size(18, 25),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(9, 25),
              scaledSize: new google.maps.Size(18, 25),
            };

            // Create a marker for each place.
            var newMarker = new google.maps.Marker({
              map: map,
              icon: icon,
              title: place.getAttribute('data-place-name'),
              position: placeLocation
            });
            var markerInfo = new google.maps.InfoWindow({
              content: '<div id="content"><h1 id="map-place-heading" class="map-place-heading">'+place.getAttribute('data-place-name')+'</h1><p id="map-place-text" class="map-place-text">'+place.getAttribute('data-place-address')+'</p></div>'
            });
            newMarker.addListener('mouseover', function(e) {
              place.classList.add('is-active');
            });
            newMarker.addListener('mouseout', function(e) {
              place.classList.remove('is-active');
            });
            newMarker.addListener('click', function(e) {
              markerInfo.open(map, newMarker);
            });
            place.addEventListener('mouseenter', function(e) {
              e.preventDefault();
              markerInfo.open(map, newMarker);
              newMarker.setAnimation(google.maps.Animation.BOUNCE);
            });
            place.addEventListener('mouseleave', function(e) {
              e.preventDefault();
              markerInfo.close();
              newMarker.setAnimation(null);
            });
            markers.push(newMarker);

            // Map Bounds look at the first 4 only to prevent ultra zoomed-out map
            if (boundsLength < 4) {
              bounds.extend(placeLocation);
              boundsLength++;
            }
          });
          map.fitBounds(bounds);

          // Listen for postalCode
          var postalCodeForm = document.querySelector('#postalCode');
          postalCodeForm.addEventListener('submit', function(e) {
            e.preventDefault();

            var postalCode = postalCodeForm.querySelector('input[name="postalCode"]').value;
            postalCode = postalCode.trim();
            postalCode = postalCode.replace(' ', '');
            var postalCodeIsValid = true;

            if (postalCodeIsValid) {
              getJSON(
                'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBqI-I62_T6zmJ7LtXNo4nT9ajaDf_-n_s&address='+postalCode+'&sensor=false',
                function(err, data) {
                  if (err !== null) {
                    // console.log('Something went wrong: ', err);
                  } else {
                    // console.log('Your query: ', data.results[0].geometry.location);
                    recenterMapByGeo(map, data.results[0].geometry.location.lat, data.results[0].geometry.location.lng, places);
                  }
                }
              );
            }
          });

          var geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          };

          // GeoLocate the user
          navigator.geolocation.getCurrentPosition(
            function(position) {
              const latitude  = position.coords.latitude;
              const longitude = position.coords.longitude;

              console.log("GeoLoc()!");
              console.log(latitude, longitude);

              recenterMapByGeo(map, position.coords.latitude, position.coords.longitude, places);
            },
            function(){},
            geoOptions
          );
      }

      function recenterMapByGeo(map, newLat, newLng, places) {

        console.log("recenterMapByGeo()!");
        console.log(newLat, newLng);

        map.setCenter(new google.maps.LatLng(newLat, newLng));

        places.forEach(function(place) {
          var order = getDistanceFromLatLonInKm(place.getAttribute('data-place-lat'), place.getAttribute('data-place-lng'), newLat, newLng);
          place.style = 'order: '+Math.round(order)+';';
        });

        map.setZoom(13);
      }
    }

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
